<template>
  <div>
    <!-- <v-spacer /> -->
    <v-row justify="center" align="center">
      <!-- 画像を選択するボタン -->
      <v-btn
        id="btn-upload"
        class="menu-item border my-7 mx-2"
        color="primary"
        @click="triggerFileInput"
      >
        <v-icon left>mdi-camera</v-icon>
        {{ $t('message.glamour_recipe_select_image') }}
      </v-btn>

      <!-- ダウンロードボタン -->
      <v-btn
        id="btn-download"
        class="menu-item border my-7 mx-2"
        color="success"
        @click="downloadImage"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t('message.glamour_recipe_download_image') }}
      </v-btn>

      <!-- 非表示のファイル入力 -->
      <input
        ref="fileInput"
        type="file"
        accept="image/*"
        style="display: none;"
        @change="inputImage"
      />
      <p style="display: none;">
        <canvas id="preview" />
      </p>
    </v-row>

    <tui-image-editor
      id="imageEditor"
      ref="tuiImageEditor"
      scoped
      :include-ui="useDefaultUI"
      :options="options"
    />
    <v-chip-group>
      <v-chip dark color="green" @click="addCopyright()">
        {{ $t('message.glamour_recipe_maker_copyright') }}
      </v-chip>

      <v-btn-toggle v-model="textAlignment" mandatory>
        <v-btn :value="'left'">{{
          $t('message.glamour_recipe_align_left')
        }}</v-btn>
        <v-btn :value="'right'">{{
          $t('message.glamour_recipe_align_right')
        }}</v-btn>
      </v-btn-toggle>

      <!-- <v-chip dark color="green">
        <v-switch
          v-model="autoName"
          :label="$t('message.glamour_recipe_maker_auto_name')"
        />
      </v-chip> -->
    </v-chip-group>
    <v-data-iterator
      sort-by="IL"
      :sort-desc="sortDesc"
      :items="filteredItems"
      :search="search"
      :footer-props="{ 'items-per-page-options': [100, 200, 400] }"
      :items-per-page="100"
      :page="page"
      :options="options"
    >
      <template v-slot:header>
        <v-toolbar dark color="gray darken-3" class="mb-1">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('message.glamour_recipe_search_for_equipment')"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="gray" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="gray" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
        <v-card>
          <v-card-title primary-title style="font-size: 1.15rem;">
            <v-icon left>mdi-filter</v-icon>
            {{ $t('message.glamour_recipe_equipment_filter') }}

            <!-- スペーサーでボタンを右寄せ -->
            <v-spacer></v-spacer>

            <!-- パネルと同じデザインのボタン -->
            <v-btn class="panel-item" @click="clearFilters">
              {{ $t('message.glamour_recipe_clear_filters') }}
            </v-btn>
          </v-card-title>

          <v-card-subtitle style="font-size: 0.7rem;">
            {{ $t('message.glamour_recipe_filter_description') }}
          </v-card-subtitle>

          <v-divider></v-divider>
          <div class="panel-container">
            <v-expansion-panels
              v-for="(panel, panelIndex) in panels[$i18n.locale]"
              :key="panelIndex"
              class="panel-item"
              accordion
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ panel.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip-group column>
                    <v-chip
                      v-for="(equipment_type, chipIndex) in panel.typeArray"
                      :key="chipIndex"
                      :color="
                        selectedEquipmentTypes.includes(equipment_type)
                          ? 'primary'
                          : ''
                      "
                      @click="toggleEquipmentTypeFilter(equipment_type)"
                    >
                      {{ equipment_type }}
                    </v-chip>
                  </v-chip-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels class="mb-2">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t('message.glamour_recipe_jobs') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip-group column>
                    <v-chip
                      v-for="(job, index) in localizedAvailableJobs"
                      :key="index"
                      :outlined="!isSelectedJob(job.name)"
                      :color="getJobChipBorderColor(job.name)"
                      :class="{ primary: isSelectedJob(job.name) }"
                      @click="toggleJobFilter(job.name)"
                    >
                      {{ job.name }}
                    </v-chip>
                  </v-chip-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card>
      </template>

      <template v-slot:default="props">
        <v-container id="equipment-view" fluid tag="section">
          <v-row justify="center">
            <v-col
              v-for="item in props.items"
              :key="item.name"
              sm="3"
              md="2"
              lg="2"
            >
              <v-card
                class="mt-4 text-center"
                min-width="100px"
                max-width="300px"
              >
                <a :href="item.url" target="_blank">
                  <v-img :src="item.image_url" />
                </a>

                <v-card-text class="text-center">
                  <h6 class="text-h5 mb-3 text--primary">
                    {{ item.name }}
                  </h6>
                  <h4 class="text-h6 mb-2 text--secondary">
                    {{ item.type }}
                  </h4>
                  <h4 class="text-h6 mb-2 text--secondary">IL:{{ item.IL }}</h4>

                  <v-row v-if="item.color === '染色:○'">
                    <v-col cols="12">
                      <!-- 染色1 ボタン -->
                      <v-btn
                        :style="{
                          backgroundColor:
                            selectedColors[item.name] &&
                            selectedColors[item.name][0],
                          color:
                            selectedColors[item.name] &&
                            getTextColor(selectedColors[item.name][0]),
                        }"
                        @click="openColorPicker(item.name, 0)"
                      >
                        {{
                          (selectedColorNames[item.name] &&
                            selectedColorNames[item.name][0]) ||
                          $t('message.glamour_recipe_dye_1')
                        }}
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <!-- 染色2 ボタン -->
                      <v-btn
                        :style="{
                          backgroundColor:
                            selectedColors[item.name] &&
                            selectedColors[item.name][1],
                          color:
                            selectedColors[item.name] &&
                            getTextColor(selectedColors[item.name][1]),
                        }"
                        @click="openColorPicker(item.name, 1)"
                      >
                        {{
                          (selectedColorNames[item.name] &&
                            selectedColorNames[item.name][1]) ||
                          $t('message.glamour_recipe_dye_2')
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!-- Separate row for Add button -->
                  <v-row>
                    <v-col>
                      <!-- ref を動的に指定 -->
                      <color-picker-dialog
                        :ref="'colorPicker_' + item.name"
                        v-model="selectedColors[item.name]"
                        @colorSelected="
                          (selectedColor, index) =>
                            updateSelectedColor(item.name, selectedColor, index)
                        "
                      ></color-picker-dialog>

                      <v-btn
                        class="mr-0"
                        color="primary"
                        rounded
                        @click="makeIcon(item.image_url, item.name, autoName)"
                      >
                        {{ $t('message.glamour_recipe_maker_add_icon') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  // ライブラリのインポート
  import axios from 'axios';
  import { ImageEditor } from '@toast-ui/vue-image-editor';
  import ColorPickerDialog from '../components/ColorPickerDialog.vue';

  // ライブラリ側で用意しているスタイルの読み込み
  import 'tui-image-editor/dist/tui-image-editor.css';
  import 'tui-color-picker/dist/tui-color-picker.css';

  // ダウンロード機能を使う際はインポートする
  import 'file-saver';

  // ロケールの設定。自分で日本語に訳して入れていく
  const localeJa = {
    // Load: 'ファイル選択',
    // Download: 'ダウンロード',
    // Apply: '適用',
    // Arrow: '矢印',
    // 'Arrow-2': '矢印2',
    // 'Arrow-3': '矢印3',
  };
  // 表示される各要素のスタイルを指定
  var theme = {
    'common.bi.image': '',
    'common.bisize.width': '0px',
    'common.bisize.height': '0px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#1e1e1e',
    'common.border': '0px',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // load button
    'loadButton.backgroundColor': '#fff',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': '#222',
    'loadButton.fontFamily': "'Mochiy Pop One', sans-serif", // フォントを変更
    'loadButton.fontSize': '12px',
    'loadButton.display': 'none',
    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': "'Mochiy Pop One', sans-serif", // フォントを変更
    'downloadButton.fontSize': '12px',
    'downloadButton.display': 'none',

    // main icons
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu icons
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#e9e9e9',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu primary color
    'submenu.backgroundColor': '#1e1e1e',
    'submenu.partition.color': '#3c3c3c',

    // submenu labels
    'submenu.normalLabel.color': '#8a8a8a',
    'submenu.normalLabel.fontWeight': 'lighter',
    'submenu.activeLabel.color': '#fff',
    'submenu.activeLabel.fontWeight': 'lighter',

    // checkbox style
    'checkbox.border': '0px',
    'checkbox.backgroundColor': '#fff',
    // 'checkbox.border': '1px solid #ccc',
    // 'checkbox.backgroundColor': '#fff',

    // range style
    'range.pointer.color': '#fff',
    'range.bar.color': '#666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#414141',
    'range.disabledBar.color': '#282828',
    'range.disabledSubbar.color': '#414141',

    'range.value.color': '#fff',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '1px solid #353535',
    'range.value.backgroundColor': '#151515',
    'range.title.color': '#fff',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '1px solid #1e1e1e',
    'colorpicker.title.color': '#fff',
  };

  export default {
    name: 'GlamourRecipeMaker',
    metaInfo: {
      title: 'GlamourRecipeMaker',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden,画像加工ツール',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'ミラプリの図解用画像を作るためのツールです。装備アイコンやコピーライトの挿入がワンタッチでできます。',
        },
        {
          property: 'og:image',
          content: 'https://e-d-e-n.site/twitter_card.jpg?3',
        },
      ],
    },
    components: {
      'tui-image-editor': ImageEditor,
      ColorPickerDialog,
    },
    props: {},
    data() {
      return {
        selectedLanguage: this.$i18n.locale || 'en',
        textAlignment: 'left',
        sortDesc: true,
        // ジョブとロールの対応表
        availableJobs: {
          ja: [
            { name: 'ナイト', role: 'ファイター' },
            { name: '戦士', role: 'ファイター' },
            { name: '暗黒騎士', role: 'ファイター' },
            { name: 'ガンブレイカー', role: 'ファイター' },
            { name: '白魔道士', role: 'ソーサラー' },
            { name: '学者', role: 'ソーサラー' },
            { name: '占星術師', role: 'ソーサラー' },
            { name: '賢者', role: 'ソーサラー' },
            { name: 'モンク', role: 'ファイター' },
            { name: '竜騎士', role: 'ファイター' },
            { name: '忍者', role: 'ファイター' },
            { name: '侍', role: 'ファイター' },
            { name: 'リーパー', role: 'ファイター' },
            { name: 'ヴァイパー', role: 'ファイター' },
            { name: '吟遊詩人', role: 'ファイター' },
            { name: '機工士', role: 'ファイター' },
            { name: '踊り子', role: 'ファイター' },
            { name: '黒魔道士', role: 'ソーサラー' },
            { name: '召喚士', role: 'ソーサラー' },
            { name: '赤魔道士', role: 'ソーサラー' },
            { name: '青魔道士', role: 'ソーサラー' },
            { name: 'ピクトマンサー', role: 'ソーサラー' },
            { name: '木工師', role: 'クラフター' },
            { name: '鍛冶師', role: 'クラフター' },
            { name: '甲冑師', role: 'クラフター' },
            { name: '彫金師', role: 'クラフター' },
            { name: '革細工師', role: 'クラフター' },
            { name: '裁縫師', role: 'クラフター' },
            { name: '錬金術師', role: 'クラフター' },
            { name: '調理師', role: 'クラフター' },
            { name: '採掘師', role: 'ギャザラー' },
            { name: '園芸師', role: 'ギャザラー' },
            { name: '漁師', role: 'ギャザラー' },
          ],
          en: [
            { name: 'PLD', role: 'War' },
            { name: 'WAR', role: 'War' },
            { name: 'DRK', role: 'War' },
            { name: 'GNB', role: 'War' },
            { name: 'WHM', role: 'Magic' },
            { name: 'SCH', role: 'Magic' },
            { name: 'AST', role: 'Magic' },
            { name: 'SGE', role: 'Magic' },
            { name: 'MNK', role: 'War' },
            { name: 'DRG', role: 'War' },
            { name: 'NIN', role: 'War' },
            { name: 'SAM', role: 'War' },
            { name: 'RPR', role: 'War' },
            { name: 'VPR', role: 'War' },
            { name: 'BRD', role: 'War' },
            { name: 'MCH', role: 'War' },
            { name: 'DNC', role: 'War' },
            { name: 'BLM', role: 'Magic' },
            { name: 'SMN', role: 'Magic' },
            { name: 'RDM', role: 'Magic' },
            { name: 'BLU', role: 'Magic' },
            { name: 'PCT', role: 'Magic' },
            { name: 'CRP', role: 'Hand' },
            { name: 'BSM', role: 'Hand' },
            { name: 'ARM', role: 'Hand' },
            { name: 'GSM', role: 'Hand' },
            { name: 'LTW', role: 'Hand' },
            { name: 'WVR', role: 'Hand' },
            { name: 'ALC', role: 'Hand' },
            { name: 'CUL', role: 'Hand' },
            { name: 'MIN', role: 'Land' },
            { name: 'BTN', role: 'Land' },
            { name: 'FSH', role: 'Land' },
          ],
        },
        selectedEquipmentTypes: [], // 選択された装備タイプを保持する配列
        selectedJobs: [], // 選択されたジョブを保持する配列を定義
        selectedRole: '', // 選択されたロール（タンク、ヒーラー、DPSなど）
        selectedColors: {}, // 各アイテムごとの色を保存
        selectedColorNames: {}, // 各アイテムごとの色の名前を保存
        panels: {
          en: [
            {
              title: 'Arms',
              typeArray: [
                "Gunbreaker's Arm",
                "Dark Knight's Arm",
                "Arcanist's Grimoire",
                "Scholar's Arm",
                "Marauder's Arm",
                "Lancer's Arm",
                "Reaper's Arm",
                "Rogue's Arm",
                "Samurai's Arm",
                "Ninja's Arm",
                "Astrologian's Arm",
                "Archer's Arm",
                "Pugilist's Arm",
                "Gladiator's Arm",
                "One-handed Conjurer's Arm",
                "One-handed Thaumaturge's Arm",
                "Red Mage's Arm",
                "Machinist's Arm",
                "Sage's Arm",
                "Blue Mage's Arm",
                "Two-handed Conjurer's Arm",
                "Two-handed Thaumaturge's Arm",
              ],
            },
            {
              title: 'Tools',
              typeArray: [
                "Botanist's Primary Tool",
                "Goldsmith's Primary Tool",
                "Miner's Primary Tool",
                "Carpenter's Primary Tool",
                "Fisher's Primary Tool",
                "Armorer's Primary Tool",
                "Weaver's Primary Tool",
                "Culinarian's Primary Tool",
                "Alchemist's Primary Tool",
                "Blacksmith's Primary Tool",
                "Leatherworker's Primary Tool",
              ],
            },
            {
              title: 'Armor',
              typeArray: ['Head', 'Body', 'Hands', 'Legs', 'Feet'],
            },
            {
              title: 'Accessories',
              typeArray: ['Rings', 'Earrings', 'Bracelets', 'Necklaces'],
            },
          ],
          ja: [
            {
              title: '武器',
              typeArray: [
                'ガンブレード',
                '両手剣',
                '両手呪具',
                '両手幻具',
                '両手斧',
                '両手槍',
                '両手鎌',
                '二刀流武器',
                '刀',
                '双剣',
                '天球儀',
                '弓',
                '格闘武器',
                '片手剣',
                '片手呪具',
                '片手幻具',
                '細剣',
                '銃',
                '賢具',
                '青魔器',
                '魔道書',
                '魔道書(学者専用)',
              ],
            },
            {
              title: '道具',
              typeArray: [
                '園芸道具(主道具)',
                '彫金道具(主道具)',
                '採掘道具(主道具)',
                '木工道具(主道具)',
                '漁道具(主道具)',
                '甲冑道具(主道具)',
                '裁縫道具(主道具)',
                '調理道具(主道具)',
                '錬金道具(主道具)',
                '鍛冶道具(主道具)',
                '革細工道具(主道具)',
              ],
            },
            {
              title: '防具',
              typeArray: ['頭防具', '胴防具', '手防具', '脚防具', '足防具'],
            },
            {
              title: 'アクセサリ',
              typeArray: ['指輪', '耳飾り', '腕輪', '首飾り'],
            },
          ],
          ko: [
            {
              title: '무기',
              typeArray: [
                '건블레이드',
                '양손검',
                '양손주술도구',
                '양손환술도구',
                '양손도끼',
                '양손창',
                '양손낫',
                '쌍수무기',
                '도',
                '쌍검',
                '천구의',
                '활',
                '격투무기',
                '한손검',
                '한손주술도구',
                '한손환술도구',
                '세검',
                '총',
                '현구',
                '청마기',
                '마도서',
                '마도서(학자전용)',
              ],
            },
            {
              title: '도구',
              typeArray: [
                '원예도구(주도구)',
                '세공도구(주도구)',
                '채광도구(주도구)',
                '목공도구(주도구)',
                '어업도구(주도구)',
                '갑옷제작도구(주도구)',
                '재봉도구(주도구)',
                '요리도구(주도구)',
                '연금술도구(주도구)',
                '대장간도구(주도구)',
                '가죽세공도구(주도구)',
              ],
            },
            {
              title: '방어구',
              typeArray: [
                '머리 방어구',
                '몸통 방어구',
                '손 방어구',
                '다리 방어구',
                '발 방어구',
              ],
            },
            {
              title: '악세사리',
              typeArray: ['반지', '귀걸이', '팔찌', '목걸이'],
            },
          ],
          zh: [
            {
              title: '武器',
              typeArray: [
                '枪刃',
                '双手剑',
                '双手咒具',
                '双手幻具',
                '双手斧',
                '双手枪',
                '双手镰刀',
                '双刀',
                '刀',
                '忍者双刀',
                '星盘',
                '弓',
                '格斗武器',
                '单手剑',
                '单手咒具',
                '单手幻具',
                '细剑',
                '枪',
                '贤具',
                '青魔器',
                '魔导书',
                '魔导书(学者专用)',
              ],
            },
            {
              title: '工具',
              typeArray: [
                '园艺工具(主)',
                '雕金工具(主)',
                '采矿工具(主)',
                '木工工具(主)',
                '渔具(主)',
                '防具工具(主)',
                '裁缝工具(主)',
                '烹饪工具(主)',
                '炼金工具(主)',
                '锻冶工具(主)',
                '制革工具(主)',
              ],
            },
            {
              title: '防具',
              typeArray: ['头防具', '胸防具', '手防具', '腿防具', '足防具'],
            },
            {
              title: '饰品',
              typeArray: ['戒指', '耳环', '手镯', '项链'],
            },
          ],
        },

        items: [],
        rawItems: [],
        id: '',
        search: '',
        page: 1,
        // typeArray: [
        //   'All',
        //   '盾',
        //   '頭防具',
        //   '胴防具',
        //   '手防具',
        //   '脚防具',
        //   '足防具',
        //   '耳飾り',
        //   '首飾り',
        //   '腕輪',
        //   '指輪 ',
        // ],
        useDefaultUI: true,
        autoName: true,
        options: {
          cssMaxWidth: document.documentElement.clientWidth - 30,
          cssMaxHeight: '700',
          selectionStyle: {
            cornerSize: 20,
          },
          includeUI: {
            loadImage: {
              path: 'concepttool.png',
              name: 'SampleImage',
            },
            // 表示メニューの設定。デフォルトでは全て表示される
            // menu: ["flip", "crop", "rotate", "filter"],
            // コンポーネント生成時に選択されるメニューの指定
            initMenu: '',
            // メニューバーの位置の指定
            menuBarPosition: 'bottom',
            uiSize: {
              width: '100%',
              height: '80vh',
            },
            theme: theme,
            locale: localeJa,
          },
        },
      };
    },
    computed: {
      // 使用するジョブを現在の言語に応じて取得
      localizedAvailableJobs() {
        return (
          this.availableJobs[this.selectedLanguage] || this.availableJobs.en
        );
      },
      filteredItems() {
        // ジョブが選択されている場合のフィルタリング
        if (this.selectedJobs.length > 0) {
          return this.items.filter((item) => {
            return this.selectedJobs.some((selectedJob) => {
              const selectedJobRole = this.getJobRole(selectedJob); // 選択されたジョブのロールを取得

              // item.jobsにジョブ名、全クラス、またはロール名が含まれていたらOK
              return (
                item.jobs.includes(selectedJob) ||
                item.jobs.includes('全クラス') ||
                (selectedJobRole && item.jobs.includes(selectedJobRole))
              );
            });
          });
        }
        // ジョブが選択されていない場合は全アイテムを表示
        return this.items;
      },
    },
    watch: {
      selectedColors: {
        deep: true, // 深いウォッチを行うことでオブジェクトの内部の変化もキャッチ
        handler(newSelectedColors) {
          for (const itemName in newSelectedColors) {
            this.updateSelectedColorName(itemName, newSelectedColors[itemName]);
          }
        },
      },
    },
    created: function () {
      this.items.forEach((item) => {
        if (!this.selectedColors[item.name]) {
          this.$set(this.selectedColors, item.name, ''); // 初期値を空文字で設定
        }
      });
      switch (this.$i18n.locale) {
        case 'ja':
          axios
            .get(
              `../../GlamourRecipe/equipments_ja.json?timestamp=${new Date().getTime()}`
            )
            .then((res) => {
              this.items = res.data;
              this.rawItems = res.data;
              this.typeArray = ['All'];
            });
          break;
        case 'en':
          axios
            .get(
              `../../GlamourRecipe/equipments_en.json?timestamp=${new Date().getTime()}`
            )
            .then((res) => {
              this.items = res.data;
              this.rawItems = res.data;
              this.typeArray = ['All'];
            });
          break;
        // case 'ko':
        //   axios
        //     .get(`../../furnishings_ko.json?timestamp=${new Date().getTime()}`)
        //     .then((res) => {
        //       this.items = res.data;
        //       this.rawItems = res.data;
        //       this.typeArray = [
        //         'All',
        //         '가구',
        //         '소품(받침대)',
        //         '소품(탁상)',
        //         '소품(벽걸이)',
        //         '소품(깔개)',
        //         '내장 건축제(내벽)',
        //         '내장 건축제(천장 조명)',
        //         '내장 건축제(바닥)',
        //       ];
        //       for (const item of this.items) {
        //         item.num = '';
        //       }
        //     });
        //   break;
        default:
          axios
            .get(
              `../../GlamourDiagramMaker/GlamourDiagramMaker_ja.json?timestamp=${new Date().getTime()}`
            )
            .then((res) => {
              this.items = res.data;
              this.rawItems = res.data;
              this.typeArray = ['All'];
            });
          break;
      }
    },
    mounted() {
      console.log('mounted'); // ここでrefsが期待通りか確認
      console.log(this.$refs); // ここでrefsが期待通りか確認
    },
    methods: {
      // 言語が変わったときに呼び出す
      changeLanguage(newLanguage) {
        this.selectedLanguage = newLanguage;
      },
      triggerFileInput() {
        this.$refs.fileInput.click(); // ファイル選択ダイアログを開く
      },
      isSelectedJob(job) {
        return this.selectedJobs.includes(job);
      },
      getJobChipBorderColor(job) {
        // 各ロールごとにジョブを分類して色を設定
        // 各ロールごとにジョブを分類して色を設定
        const tankJobs = [
          'ナイト',
          '戦士',
          '暗黒騎士',
          'ガンブレイカー',
          'PLD',
          'WAR',
          'DRK',
          'GNB',
        ];
        const healerJobs = [
          '白魔道士',
          '学者',
          '占星術師',
          '賢者',
          'WHM',
          'SCH',
          'AST',
          'SGE',
        ];
        const dpsJobs = [
          'モンク',
          '竜騎士',
          '忍者',
          '侍',
          'リーパー',
          '吟遊詩人',
          '機工士',
          '踊り子',
          '黒魔道士',
          '召喚士',
          '赤魔道士',
          'ヴァイパー',
          '青魔道士',
          'ピクトマンサー',
          'MNK',
          'DRG',
          'NIN',
          'SAM',
          'RPR',
          'BRD',
          'MCH',
          'DNC',
          'BLM',
          'SMN',
          'RDM',
          'VPR',
          'BLU',
          'PCT',
        ];
        const crafterJobs = [
          '木工師',
          '鍛冶師',
          '甲冑師',
          '彫金師',
          '革細工師',
          '裁縫師',
          '錬金術師',
          '調理師',
          'CRP',
          'BSM',
          'ARM',
          'GSM',
          'LTW',
          'WVR',
          'ALC',
          'CUL',
        ];
        const gathererJobs = ['採掘師', '園芸師', '漁師', 'MIN', 'BTN', 'FSH'];
        // タンクロールの場合
        if (tankJobs.includes(job)) {
          return 'blue'; // タンクは紫色
        }

        // ヒーラーロールの場合
        if (healerJobs.includes(job)) {
          return 'green'; // ヒーラーは緑色
        }

        // DPSロールの場合
        if (dpsJobs.includes(job)) {
          return 'red'; // DPSはオレンジ色
        }

        // クラフターロールの場合
        if (crafterJobs.includes(job)) {
          return 'purple'; // クラフターは紫色
        }

        // ギャザラーロールの場合
        if (gathererJobs.includes(job)) {
          return 'brown'; // ギャザラーは肌色 (ウェジウッドカラー)
        }

        // その他（例えばクラフターやギャザラー）に対してのデフォルト色
        return 'gray'; // デフォルトの色
      },
      clearFilters() {
        // フィルターをクリア
        this.selectedEquipmentTypes = [];
        this.selectedJobs = [];
        this.updateItems(); // 全アイテムを表示
      },
      // 指定されたジョブのロールを取得するメソッド
      getJobRole(jobName) {
        const job = this.localizedAvailableJobs.find(
          (job) => job.name === jobName
        );
        return job ? job.role : null;
      },
      hexToRgb(hex) {
        // 16進数の色コードをRGBに変換
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : { r: 0, g: 0, b: 0 }; // デフォルト値として黒を返す
      },
      getLuminance(hexColor) {
        const rgb = this.hexToRgb(hexColor);
        const luminance =
          (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;
        return luminance;
      },
      getTextColor(bgColor) {
        if (!bgColor) return '#000'; // デフォルトで黒色
        const rgb = this.hexToRgb(bgColor);
        // 明るさを計算 (Luminance Calculation)
        const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
        // 明るさが一定値より低ければ白色、そうでなければ黒色
        return brightness < 128 ? '#fff' : '#000';
      },
      openColorPicker(itemName, index) {
        const colorPickerRef = this.$refs['colorPicker_' + itemName];
        if (colorPickerRef && typeof colorPickerRef[0].open === 'function') {
          colorPickerRef[0].open(index); // インデックスを渡して色選択を開く
        }
      },
      // 色とインデックスを受け取って selectedColors に反映
      updateSelectedColor(itemName, selectedColor, index) {
        // selectedColors[item.name] が存在しない場合は配列として初期化
        if (!Array.isArray(this.selectedColors[itemName])) {
          this.$set(this.selectedColors, itemName, []);
        }
        if (!Array.isArray(this.selectedColorNames[itemName])) {
          this.$set(this.selectedColorNames, itemName, []);
        }

        // 正常な色かどうかをチェック
        if (selectedColor && selectedColor.color && selectedColor.name) {
          this.$set(this.selectedColors[itemName], index, selectedColor.color);
          this.$set(
            this.selectedColorNames[itemName],
            index,
            selectedColor.name
          );
        } else {
          console.error('Invalid color or name', selectedColor);
        }
      },
      updateSelectedColorName(itemName, color) {
        const swatch = this.getSelectedSwatch(color);
        if (swatch) {
          this.$set(this.selectedColorNames, itemName, swatch.name);
        }
      },
      getSelectedSwatch(color) {
        for (const key in this.$refs) {
          if (key.startsWith('colorPicker_')) {
            const picker = this.$refs[key][0];
            if (picker && picker.swatches) {
              const foundSwatch = picker.swatches.find(
                (swatch) => swatch.color === color
              );
              if (foundSwatch) return foundSwatch;
            }
          }
        }
        return null;
      },
      toggleEquipmentTypeFilter(equipmentType) {
        const index = this.selectedEquipmentTypes.indexOf(equipmentType);
        if (index === -1) {
          this.selectedEquipmentTypes.push(equipmentType);
        } else {
          this.selectedEquipmentTypes.splice(index, 1);
        }
        this.updateItems();
      },
      toggleJobFilter(job) {
        const index = this.selectedJobs.indexOf(job);
        if (index === -1) {
          this.selectedJobs.push(job);
        } else {
          this.selectedJobs.splice(index, 1);
        }
        this.updateItems();
      },
      updateItems() {
        // フィルタ指定がない場合は全アイテムを返す
        if (
          this.selectedEquipmentTypes.length === 0 &&
          this.selectedJobs.length === 0
        ) {
          this.items = this.rawItems;
          return;
        }

        // フィルタ指定がある場合にフィルタリング
        this.items = this.rawItems.filter((item) => {
          const equipmentMatch =
            this.selectedEquipmentTypes.length === 0 ||
            this.selectedEquipmentTypes.includes(item.type);

          const jobMatch =
            this.selectedJobs.length === 0 ||
            this.selectedJobs.some((job) => item.jobs.includes(job));

          return equipmentMatch && jobMatch;
        });

        console.log('Filtered Items:', this.items);
      },
      makeIcon: function (imageUrl, name, autoName) {
        console.log('autoName:' + autoName);
        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        console.log(filename);
        var url = '../../img/equipments/' + filename;
        console.log(url);

        var image = new Image();
        image.crossOrigin = 'use-credentials'; // または 'use-credentials' 必要に応じて
        image.src = url;
        image.onload = function () {
          // 画像ロードが完了してからキャンバスの準備をする
          var canvas = document.getElementById('preview');
          var ctx = canvas.getContext('2d');

          // キャンバスのサイズを画像サイズに合わせる
          canvas.width = 128 + 30;
          canvas.height = 128;

          // 名前と色名を表示するために高さを調整
          if (autoName === true) {
            const nameHeight = 30; // 名前の表示に必要な高さ
            const colorNameHeight = 30 * 2; // 2つの色名を表示するための高さ

            // 名前と色名を描画するためのスペースを追加
            canvas.height = canvas.height + nameHeight + colorNameHeight;

            // 名前と色名の幅を取得
            const nameWidth = ctx.measureText(name).width;
            const colorName1Width =
              _this.selectedColorNames[name] &&
              _this.selectedColorNames[name][0]
                ? ctx.measureText(_this.selectedColorNames[name][0]).width
                : 0; // デフォルト幅は0
            const colorName2Width =
              _this.selectedColorNames[name] &&
              _this.selectedColorNames[name][1]
                ? ctx.measureText(_this.selectedColorNames[name][1]).width
                : 0; // デフォルト幅は0

            // 両方の幅を比較して大きい方を使用
            const maxWidth = Math.max(
              nameWidth,
              colorName1Width,
              colorName2Width
            );

            if (maxWidth * 2 > canvas.width) {
              canvas.width = maxWidth * 2 + 20;
            }
          }

          // 文字のスタイルを指定
          ctx.font = '20px "Mochiy Pop One", sans-serif';
          ctx.lineWidth = '6';
          ctx.lineJoin = 'miter';
          ctx.miterLimit = '5';
          // キャンバスに画像を描画（開始位置0,0）
          console.log('textAlignment');
          console.log(_this.textAlignment);
          var imageX =
            _this.textAlignment === 'left' ? 0 : canvas.width - image.width; // 右寄せの場合は幅を引く

          ctx.drawImage(image, imageX, 0); // 画像を描画（X座標は imageX, Y座標は 0）

          // 名前の描画
          if (autoName === true) {
            ctx.textBaseline = 'bottom';
            ctx.textAlign = _this.textAlignment;
            ctx.fillStyle = '#ffffff';
            var nameY = canvas.height - 65; // 名前の位置
            ctx.strokeText(
              name,
              _this.textAlignment === 'left' ? 0 : canvas.width,
              nameY
            ); // 名前を左寄せで描画
            ctx.fillText(
              name,
              _this.textAlignment === 'left' ? 0 : canvas.width,
              nameY
            );
          }

          // 色名の描画（2色分）
          const colors = _this.selectedColors[name] || []; // selectedColors が存在しない場合は空配列を使用
          const colorNames = _this.selectedColorNames[name] || []; // selectedColorNames が存在しない場合は空配列を使用
          const backgroundHeight = 25; // 背景の高さを定義

          if (colors.length > 0 && colorNames.length > 0) {
            ctx.font = '16px "Mochiy Pop One", sans-serif';
            ctx.lineWidth = 4;
            ctx.textAlign = _this.textAlignment;
            ctx.textBaseline = 'middle';

            // 色名1の背景と色名を描画
            if (colors[0] && colorNames[0]) {
              const textWidth1 = ctx.measureText(colorNames[0]).width;
              const padding = 10;
              const backgroundWidth1 = textWidth1 + padding * 2;

              const backgroundY1 = canvas.height - 60; // 色名1の位置
              const backgroundX1 =
                _this.textAlignment === 'left'
                  ? 0
                  : canvas.width - backgroundWidth1;

              // 色名1の背景
              ctx.fillStyle = colors[0];
              ctx.fillRect(
                backgroundX1,
                backgroundY1,
                backgroundWidth1,
                backgroundHeight
              );

              // 色名1のテキスト
              ctx.fillStyle = '#ffffff';
              ctx.strokeStyle = '#000000';
              ctx.strokeText(
                colorNames[0],
                _this.textAlignment === 'left'
                  ? padding
                  : canvas.width - padding,
                backgroundY1 + backgroundHeight / 2
              );
              ctx.fillText(
                colorNames[0],
                _this.textAlignment === 'left'
                  ? padding
                  : canvas.width - padding,
                backgroundY1 + backgroundHeight / 2
              );
            }

            // 色名2の背景と色名を描画
            if (colors[1] && colorNames[1]) {
              const textWidth2 = ctx.measureText(colorNames[1]).width;
              const padding = 10;
              const backgroundWidth2 = textWidth2 + padding * 2;

              const backgroundY2 = canvas.height - 30; // 色名2の位置
              const backgroundX2 =
                _this.textAlignment === 'left'
                  ? 0
                  : canvas.width - backgroundWidth2;

              // 色名2の背景
              ctx.fillStyle = colors[1];
              ctx.fillRect(
                backgroundX2,
                backgroundY2,
                backgroundWidth2,
                backgroundHeight
              );

              // 色名2のテキスト
              ctx.fillStyle = '#ffffff';
              ctx.strokeStyle = '#000000';
              ctx.strokeText(
                colorNames[1],
                _this.textAlignment === 'left'
                  ? padding
                  : canvas.width - padding,
                backgroundY2 + backgroundHeight / 2
              );
              ctx.fillText(
                colorNames[1],
                _this.textAlignment === 'left'
                  ? padding
                  : canvas.width - padding,
                backgroundY2 + backgroundHeight / 2
              );
            }
          }

          _this.addIcon(canvas.toDataURL());

          // 最後にスクロール処理を追加
          window.scrollTo({
            top: 0, // ページの一番上にスクロール
            behavior: 'smooth', // スムーズにスクロールする
          });
        };
        const _this = this;
      },

      addIcon: function (url) {
        this.$refs.tuiImageEditor
          .invoke('addImageObject', url)
          .then((objectProps) => {
            console.log(objectProps.id);
            this.id = objectProps.id;
            console.log(this.id);
          });
      },
      addCopyright: function () {
        this.$refs.tuiImageEditor.invoke('addText', '© SQUARE ENIX', {
          styles: {
            fill: '#fff',
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: "'Mochiy Pop One', sans-serif",
          },
        });
      },
      // setTimeout(() => {
      //   console.log(this.id)
      //   this.$refs.tuiImageEditor.invoke('setObjectProperties', (this.id, {
      //     left: 100,
      //     top: 100,
      //     width: 200,
      //     height: 200,
      //     opacity: 0.5,
      //   }))
      // }, 100)
      base64ToBlob: function (data) {
        var mimeString = '';
        var raw, uInt8Array, i, rawLength;
        var rImageType = /data:(image\/.+);base64,/;

        raw = data.replace(rImageType, function (header, imageType) {
          mimeString = imageType;

          return '';
        });

        raw = atob(raw);
        rawLength = raw.length;
        uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

        for (i = 0; i < rawLength; i += 1) {
          uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: mimeString });
      },
      inputImage: function (event) {
        console.log(event);
        var file;
        var supportingFileAPI = !!(
          window.File &&
          window.FileList &&
          window.FileReader
        );

        if (!supportingFileAPI) {
          alert('This browser does not support file-api');
        }

        file = event.target.files[0];
        this.$refs.tuiImageEditor
          .invoke('loadImageFromFile', file)
          .then(function (result) {
            console.log(result);
          });
        this.$refs.tuiImageEditor.invoke('clearUndoStack');
      },

      downloadImage: function () {
        var supportingFileAPI = !!(
          window.File &&
          window.FileList &&
          window.FileReader
        );
        // var imageName = this.imageEditor.getImageName()
        var imageName = this.$refs.tuiImageEditor.invoke('getImageName');
        // var dataURL = this.imageEditor.toDataURL()
        var dataURL = this.$refs.tuiImageEditor.invoke('toDataURL');
        var blob, type, w;

        if (supportingFileAPI) {
          blob = this.base64ToBlob(dataURL);
          type = blob.type.split('/')[1];
          if (imageName.split('.').pop() !== type) {
            imageName += '.' + type;
          }

          // Library: FileSaver - saveAs
          saveAs(blob, imageName); // eslint-disable-line
        } else {
          alert('This browser needs a file-server');
          w = window.open();
          w.document.body.innerHTML = '<img src="' + dataURL + '">';
        }
      },
    },
  };
</script>

<style scoped>
  * {
    font-family: 'Mochiy Pop One', sans-serif;
  }
</style>
